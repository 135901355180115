'use client'

/**
 * Base UI Components
 */
import SiteLogo from '~@Atoms/site-logo/site-logo'
import ButtonSecondary from '~@Atoms/button-secondary/button-secondary'
import LinkItem from '~@Atoms/link-item/link-item'
import ModalWindow from '~@Atoms/modal-window/modal-window'
import BlockMenu from '~@Molecules/block-menu/block-menu'
import BlockMenuAlternate from '~@Molecules/block-menu-alternate/block-menu-alternate'
import ListLinks from '~@Molecules/list-links/list-links'
import SearchForm from '~@Organisms/search-form/search-form'
import SwitcherLanguageModule from '~@Components/utils/switcher-language'

/**
 * React Hooks and Icons
 */
import { useEffect, useState, useRef } from 'react'
import { X, List, MagnifyingGlass } from '@phosphor-icons/react'

/**
 * Types
 */
import { ISectionHeader } from '~@Types/components/ISectionHeader'

/**
 * SectionHeader Component
 *
 * A responsive header component that handles:
 * - Fixed positioning on scroll
 * - Mobile/Desktop layouts
 * - Search functionality
 * - Language switching
 * - Navigation menus
 *
 * @param {Object} props - Component props
 * @param {Object} props.logo - Logo configuration
 * @param {Object} props.links - Navigation links for desktop
 * @param {Object} props.linksMobile - Navigation links for mobile
 * @param {Object} props.secondaryMenu - Secondary menu items
 * @param {Object} props.searchForm - Search form configuration
 * @param {Object} props.button - Optional CTA button
 * @param {Object} props.labels - Text labels
 */
export default function SectionHeader({
  logo,
  links,
  linksMobile,
  secondaryMenu,
  searchForm,
  button,
  labels,
}: ISectionHeader) {
  /**
   * Component States
   * @state {boolean} isFixed - Controls if header is fixed at top of viewport
   * @state {boolean} isDisplayed - Controls header visibility during scroll
   * @state {number} lastScrollY - Tracks last scroll position for scroll direction
   * @state {HTMLDivElement} headerRef - Reference to header DOM element
   * @state {boolean} mobileMenuOpen - Controls mobile menu visibility
   * @state {boolean} showPopin - Controls search modal visibility
   * @state {boolean} isMobile - Tracks if viewport is in mobile breakpoint
   */
  const [isFixed, setIsFixed] = useState(false)
  const [isDisplayed, setIsDisplayed] = useState(true)
  const lastScrollY = useRef(0)
  const headerRef = useRef<HTMLDivElement | null>(null)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [showPopin, setShowPopin] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  /**
   * Handles header transition states based on scroll position
   * - Sets fixed state when scrolled past header height
   * - Controls header visibility based on scroll direction
   */
  const headerTransition = () => {
    if (headerRef.current) {
      const headerOffsetHeight = headerRef.current.offsetHeight
      const headerMarginBottom = parseFloat(
        window.getComputedStyle(headerRef.current).marginBottom
      )
      const headerHeight = headerOffsetHeight + headerMarginBottom
      const scrollY = window.scrollY

      if (scrollY > headerHeight) {
        setIsFixed(true)
        if (scrollY < lastScrollY.current) {
          setIsDisplayed(true)
        }
      } else {
        setIsFixed(false)
        setIsDisplayed(true)
      }

      if (scrollY > headerHeight + 300) {
        if (scrollY > lastScrollY.current) {
          const scrollDifference = Math.abs(scrollY - lastScrollY.current)
          if (scrollDifference > 50) {
            setIsDisplayed(false)
          }
        }
      }

      lastScrollY.current = scrollY
    }
  }

  /**
   * Updates mobile state based on viewport width
   * Breakpoint set at 960px
   */
  const handleResize = () => {
    window.innerWidth < 960 ? setIsMobile(true) : setIsMobile(false)
  }

  /**
   * Effect: Manages body scroll lock when mobile menu is open
   */
  useEffect(() => {
    mobileMenuOpen
      ? document.body.classList.add('PreventFromScroll')
      : document.body.classList.remove('PreventFromScroll')

    return () => {
      document.body.classList.remove('PreventFromScroll')
    }
  }, [mobileMenuOpen])

  /**
   * Effect: Handles viewport resize events and initial mobile state
   */
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isMobile])

  /**
   * Effect: Manages scroll event listener for header transition
   */
  useEffect(() => {
    window.addEventListener('scroll', headerTransition)
    headerTransition()

    return () => {
      window.removeEventListener('scroll', headerTransition)
    }
  }, [])

  /**
   * Effect: Réinitialise l'affichage lors des changements de route
   */
  useEffect(() => {
    setIsDisplayed(true)
    lastScrollY.current = 0
  }, [])

  return (
    <header
      ref={headerRef}
      role="banner"
      className={`py-md px-lg desktop-large:bg-[transparent] tablet:px-3xl relative z-[500]
        ${isFixed ? 'sticky top-none duration-500 animate-opacity-display-slow' : 'desktop-large:pb-sm desktop-large:pt-lg animate-opacity-display'} 
        ${isDisplayed ? (isFixed ? 'translate-y-none' : 'translate-y-none') : 'translate-y-[-100%]'}
      }`}
    >
      <div
        className={`container desktop-large:px-3xl ${isFixed ? (isMobile ? 'text-right' : 'text-center') : ''}`}
      >
        <div
          className={`hidden typeface-caption-standard text-grey-dark gap-xl desktop-large:justify-end desktop-large:flex ${isFixed ? 'desktop-large:hidden' : ''}`}
        >
          {secondaryMenu && (
            <ListLinks
              className="flex items-center gap-xl"
              links={secondaryMenu}
              ariaLabel={labels.secondaryNav}
            />
          )}
          <SwitcherLanguageModule className="text-grey-dark" />
          <LinkItem
            tagElt={'button'}
            aria-expanded={false}
            aria-controls={`quickSearch`}
            onClick={() => setShowPopin(true)}
            className="relative flex items-center gap-2xs before:absolute before:content-[''] before:bottom-none before:left-none before:h-4xs before:bg-[currentColor] before:pointer-events-none before:w-none before:transition-[width] before:duration-900 before:ease-[cubic-bezier(.135,.9,.15,1)] hover:before:w-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary"
          >
            {labels.label}
            <MagnifyingGlass size={16} aria-hidden="true" weight="bold" />
          </LinkItem>
        </div>
        <div
          className={`justify-between flex desktop-large:justify-center ${isFixed ? 'bg-white-white/70 shadow-lg rounded-full py-xs pl-xs pr-md backdrop-blur-lg items-center inline-flex gap-xl desktop-large:py-none desktop-large:px-md desktop-large:gap-none' : 'desktop-large:items-end'}`}
        >
          <SiteLogo
            {...logo}
            isReduced={isFixed || isMobile}
            withoutText={isFixed}
          />
          <div className="desktop-large:flex desktop-large:flex-1 desktop-large:justify-center">
            <div className="desktop-large:hidden">
              <button
                type="button"
                className="bg-white-white inline-flex items-center justify-center p-standard shadow-[0px_5px_25px_-5px_rgba(0,0,0,0.10)] rounded-md p-xs transition-colors hover:text-brand-secondary focus:outline-black-black focus:outline-2 focus:outline-offset-2"
                aria-expanded={mobileMenuOpen}
                aria-controls="main-menu"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">{labels.menuOpenLabel}</span>
                <List size={24} aria-hidden="true" />
              </button>
            </div>
            <BlockMenu
              {...links}
              ariaLabel={labels.mainNavDesktop}
              className={
                !isFixed
                  ? 'desktop-large:shadow-[0px_8px_10px_-6px_rgba(0,0,0,0.10),_0px_5px_25px_-5px_rgba(0,0,0,0.10)] desktop-large:rounded-full'
                  : ''
              }
              ariaHidden={isMobile}
            />
          </div>
          {button && (
            <ButtonSecondary
              className={`hidden mb-[18px] ${isFixed ? 'desktop-large:hidden' : 'desktop-large:flex'}`}
              {...button}
            />
          )}
        </div>
      </div>
      <div
        className={`SectionHeader-mobileMenu duration-500 transition-transform overflow-y-auto bg-brand-primary text-white-white absolute w-full h-screen top-none left-none px-lg desktop-large:hidden tablet:px-3xl py-md ${mobileMenuOpen ? 'translate-y-none' : '-translate-y-[100%]'}`}
      >
        <div className="flex justify-between items-start mb-3xl">
          <SiteLogo {...logo} isReduced={true} isReversed={true} />
          <div className="flex items-center gap-sm">
            <LinkItem
              tagElt={'button'}
              aria-expanded={showPopin}
              aria-controls="quickSearch"
              onClick={() => setShowPopin(true)}
              className="duration-300 transition-all p-sm rounded-full hover:bg-white-white hover:text-brand-primary"
            >
              <span className="sr-only"> {labels.label}</span>
              <MagnifyingGlass size={24} aria-hidden="true" />
            </LinkItem>
            <button
              type="button"
              className="duration-300 transition-all inline-flex items-center justify-center p-sm rounded-full hover:bg-white-white hover:text-brand-primary hover:rotate-90"
              aria-expanded={mobileMenuOpen}
              aria-controls="main-menu-mobile"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">{labels.menuCloseLabel}</span>
              <X size={24} aria-hidden="true" />
            </button>
          </div>
        </div>
        <BlockMenuAlternate
          {...linksMobile}
          ariaLabel={labels.mainNavMobile}
          onClose={() => setMobileMenuOpen(false)}
          ariaHidden={!isMobile}
        />
        {button && (
          <ButtonSecondary
            {...button}
            onClick={() => setMobileMenuOpen(false)}
          />
        )}
        <div className="flex gap-md mt-3xl">
          {secondaryMenu && (
            <ListLinks
              className="flex items-center gap-md typeface-caption-standard text-white-white"
              links={secondaryMenu}
              onClose={() => setMobileMenuOpen(false)}
            />
          )}
          <SwitcherLanguageModule />
        </div>
      </div>
      <ModalWindow
        popinState={showPopin}
        onClose={() => {
          setShowPopin(false)
        }}
        title={labels.label}
        adaptedHeight={true}
        id="quickSearch"
      >
        <>
          <SearchForm
            {...searchForm}
            onClose={() => {
              setShowPopin(false)
              setMobileMenuOpen(false)
            }}
          />
        </>
      </ModalWindow>
    </header>
  )
}
