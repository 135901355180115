'use client'

import { useEffect, useState, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from '@headlessui/react'

import LinkItem from '~@Components/atoms/link-item/link-item'
import { IBlockMenu } from '~@Types/components/IBlockMenu'
import { Itextarea } from '~@Types/components/ITextArea'
import TeaserDescription from '~@Components/molecules/teaser-description/teaser-description'
import ExtraLink from '~@Components/atoms/extra-link/extra-link'

export default function BlockMenu({
  className,
  ariaLabel,
  ariaHidden,
  links,
}: IBlockMenu) {
  const [openIndex, setOpenIndex] = useState<number | null>(null)
  const menuRefs = useRef<(HTMLLIElement | null)[]>([])

  const textLinkStyles =
    'typeface-button1-standard relative transition-colors focus:outline-none focus:ring-0'

  const handleOutsideClick = (event: MouseEvent) => {
    /* const target = event.target as HTMLElement

    const isInsideMenu = menuRefs.current.some(
      (ref) => ref && ref.contains(target)
    )

    if (!isInsideMenu) {
      setOpenIndex(null)
    } */

    setOpenIndex(null)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setOpenIndex(null)
    }
  }

  const handleScroll = () => {
    if (openIndex !== null) {
      setOpenIndex(null)
    }
  }

  useEffect(() => {
    const main = document.querySelector('.main-content')
    if (main)
      main.classList.toggle('OverlayBlur', openIndex === null ? false : true)
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('scroll', handleScroll)
      main?.classList.remove('OverlayBlur')
    }
  }, [openIndex])

  const computedtextarea = ({
    title,
    description,
    picture,
    link,
  }: Itextarea) => {
    return (
      <>
        {picture && (
          <TeaserDescription
            className="basis-[41%]"
            title={title}
            description={description}
            link={link}
            picture={picture}
          />
        )}
      </>
    )
  }

  const computedLinks = (links, isSubMenu) => {
    return links.map((link, index) => {
      const { subLinks, ...restLinkProps } = link
      return (
        <li key={index} role="menuitem">
          <ExtraLink {...restLinkProps} />
        </li>
      )
    })
  }

  const computedList = () => {
    return links.map((link, index) => {
      const { subLinks, ...restLinkProps } = link
      const restLinkPropsWithClick = {
        ...restLinkProps,
        onClick: () => setOpenIndex(null),
      }
      const isOpen = openIndex === index

      if (subLinks && subLinks.length > 0) {
        return (
          <li
            className={`relative ${isOpen ? 'BlockMenu--current' : ''}`}
            role="menuitem"
            key={index}
            ref={(el: HTMLLIElement | null) => {
              if (menuRefs.current) menuRefs.current[index] = el
            }}
          >
            <Popover>
              <PopoverButton
                onClick={() => setOpenIndex(isOpen ? null : index)}
                onKeyDown={handleKeyDown}
                className={twMerge(
                  textLinkStyles,
                  'group p-xl flex items-center gap-x-small focus-visible:outline-black-black focus-visible:outline-2 focus-visible:outline-offset-2'
                )}
              >
                <span className="relative group-hover:before:w-full before:absolute before:content-[''] before:-bottom-3xs before:left-none before:h-4xs before:bg-[currentColor] before:pointer-events-none before:w-none before:transition-[width] before:duration-900 before:ease-[cubic-bezier(.135,.9,.15,1)]">
                  {link.children}
                </span>
                <svg
                  className={twMerge(
                    'absolute z-[100] bottom-none left-1/2 transform -translate-x-1/2 -translate-y-[8px]',
                    isOpen ? '' : 'hidden'
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="11"
                  viewBox="0 0 20 11"
                  fill="none"
                >
                  <defs>
                    <filter
                      id="dropShadow"
                      x="0"
                      y="0"
                      width="100%"
                      height="200%"
                    >
                      <feDropShadow
                        dx="0"
                        dy="-1"
                        stdDeviation="2"
                        floodColor="black"
                        floodOpacity="0.05"
                      ></feDropShadow>
                    </filter>
                  </defs>
                  <path
                    d="M9.12689 0.707052L0.707273 9.12609C0.0772718 9.75605 0.523442 10.8332 1.41437 10.8332L18.253 10.833C19.1439 10.833 19.5901 9.75588 18.9601 9.12592L10.5411 0.707067C10.1506 0.316559 9.51741 0.316553 9.12689 0.707052Z"
                    fill="white"
                    filter="url(#dropShadow)"
                  />
                </svg>
              </PopoverButton>
              <Transition
                show={isOpen}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="BlockMenu-submenu absolute top-full left-[0px] w-full desktop-large:absolute table:z-[1] desktop-large:-left-[109px] desktop-large:-mt-xs desktop-large:top-full desktop-large:max-w-screen desktop-large:min-w-[708px] text-left flex justify-between desktop-large:overflow-hidden rounded-xl bg-white-white shadow-2xl p-lg">
                  {link.textarea && computedtextarea(link.textarea)}
                  <div className="BlockMenu-links basis-[56%]">
                    <ul className="BlockMenu-linksList">
                      {computedLinks(subLinks, true)}
                    </ul>
                  </div>
                </PopoverPanel>
              </Transition>
            </Popover>
          </li>
        )
      } else {
        return (
          <li key={index} role="menuitem">
            <LinkItem
              className={twMerge('group p-xl', textLinkStyles)}
              {...restLinkProps}
            >
              <span className="relative group-hover:before:w-full before:absolute before:content-[''] before:-bottom-3xs before:left-none before:h-4xs before:bg-[currentColor] before:pointer-events-none before:w-none before:transition-[width] before:duration-900 before:ease-[cubic-bezier(.135,.9,.15,1)]">
                {restLinkProps.children}
              </span>
            </LinkItem>
          </li>
        )
      }
    })
  }

  return (
    <div
      className={twMerge('BlockMenu hidden desktop-large:flex', className)}
      {...(ariaHidden && { 'aria-hidden': ariaHidden })}
    >
      <nav role="navigation" {...(ariaLabel && { 'aria-label': ariaLabel })}>
        <PopoverGroup id="main-menu">
          <ul
            className={twMerge(
              'desktop-large:flex flex-col desktop-large:flex-row gap-x-standard desktop-large:justify-center desktop-large:items-center',
              openIndex ? 'flex' : 'hidden'
            )}
            role="menu"
          >
            {computedList()}
          </ul>
        </PopoverGroup>
      </nav>
    </div>
  )
}
