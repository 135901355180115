import { twMerge } from 'tailwind-merge'
import LinkItem from '~@Atoms/link-item/link-item'
import {
  LinkedinLogo,
  YoutubeLogo,
  InstagramLogo,
  FacebookLogo,
  XLogo,
  Link,
} from '@phosphor-icons/react/dist/ssr'

import { INavSocial } from '~@Types/components/INavSocial'

export default function NavSocial({
  isHorizontal,
  isAlternativeVersion,
  className,
  list,
  ariaLabel,
}: INavSocial) {
  const mapSocialIcon = (icon: React.ReactNode) => {
    switch (icon) {
      case 'linkedin':
        return <LinkedinLogo size={24} weight="fill" />
      case 'youtube':
        return <YoutubeLogo size={24} weight="fill" />
      case 'instagram':
        return <InstagramLogo size={24} weight="fill" />
      case 'facebook':
        return <FacebookLogo size={24} weight="fill" />
      case 'x':
        return <XLogo size={24} weight="fill" />
      default:
        return <Link size={24} />
    }
  }
  const formattedList = list.map((link) => {
    return {
      ...link,
      ariaLabel: link.children?.toString() ?? 'social',
      children: mapSocialIcon(link.children),
    }
  })

  return (
    <nav className={twMerge('NavSocial', className)} aria-label={ariaLabel}>
      <ul
        className={`NavSocial-list flex w-[40px] ${isHorizontal ? 'gap-x-md' : 'flex-col gap-y-md'}`}
        role="list"
      >
        {formattedList.map((link, index) => (
          <li
            key={index}
            className="NavSocial-item"
            aria-label={`${link.ariaLabel} Profile`}
          >
            <LinkItem
              className={`NavSocial-link flex flex-col items-center justify-center w-[40px] h-[40px] ${isAlternativeVersion ? 'bg-brand-primary' : 'bg-brand-secondary'} text-white-white rounded-full transition-colors duration-300 cursor-pointer hover:bg-black-black focus:outline-black-black focus:outline-2 focus:outline-offset-2`}
              {...link}
            />
          </li>
        ))}
      </ul>
    </nav>
  )
}
