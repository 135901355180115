import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Phone } from '@phosphor-icons/react/dist/ssr'
import { MapPin } from '@phosphor-icons/react/dist/ssr'

import { IBlockAddress } from '~@Types/components/IBlockAddress'

export default function BlockAddress({
  className,
  address1,
  address2,
  postalCode,
  city,
  country,
  phone,
  ariaLabelAddress = 'Adresse',
  ariaLabelPhone = 'Numéro de téléphone',
}: IBlockAddress) {
  const iconSize = 16
  const rowCssClasses = 'flex gap-x-sm'

  return (
    <address
      className={twMerge(
        'BlockAddress flex flex-col gap-y-md typeface-body2-standard not-italic',
        className
      )}
      aria-label={`${ariaLabelAddress} / ${ariaLabelPhone}`}
    >
      <div
        className={twMerge('BlockAddress-address items-start', rowCssClasses)}
      >
        <MapPin
          className="shrink-0 mt-2xs"
          size={iconSize}
          aria-hidden="true"
        />
        <p className="flex flex-wrap flex-row justify-start items-baseline gap-x-xs desktop:flex-col">
          <span>{address1}</span>
          <span>{address2}</span>
          <span>
            {postalCode} {city}
          </span>
          <span>{country}</span>
        </p>
      </div>
      {phone && (
        <div
          className={twMerge('BlockAddress-phone items-start', rowCssClasses)}
        >
          <Phone
            className="shrink-0 mt-2xs"
            size={iconSize}
            aria-hidden="true"
          />
          <a
            href={`tel:${phone.replace(/\s/g, '')}`}
            aria-label={ariaLabelPhone}
          >
            {phone}
          </a>
        </div>
      )}
    </address>
  )
}
