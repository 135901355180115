import { twMerge } from 'tailwind-merge'

import LinkItem from '~@Atoms/link-item/link-item'
import IconLogo from '~@Atoms/icon-logo/icon-logo'
import { ILinkProps } from '~@Types/components/ILinkProps'

export default function SiteLogo({
  className,
  link,
  logoWidth,
  logoHeight,
  isReduced,
  isReversed,
  withoutText,
}: {
  className?: string
  link: ILinkProps
  logoWidth?: number
  logoHeight?: number
  isReduced?: boolean
  isReversed?: boolean
  withoutText?: boolean
}) {
  return (
    <LinkItem
      className={twMerge(
        'SiteLogo group focus:outline-black-black focus:outline-2 focus:outline-offset-2',
        className
      )}
      {...link}
      ariaLabel="Logo"
    >
      <IconLogo
        className="fill-brand-primary transition-colors group-hover:fill-brand-secondary"
        width={logoWidth}
        height={logoHeight}
        isReduced={isReduced}
        isReversed={isReversed}
        withoutText={withoutText}
        aria-hidden="true"
      />
    </LinkItem>
  )
}
