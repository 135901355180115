import LinkItem from '~@Atoms/link-item/link-item'
import { IListLinks } from '~@Types/components/IListLinks'

export default function ListLinks({
  className,
  classNameNav,
  ariaLabel,
  links,
  onClose,
}: IListLinks) {
  return (
    <nav
      role="navigation"
      {...(ariaLabel && { 'aria-label': ariaLabel })}
      {...(classNameNav && { className: classNameNav })}
    >
      <ul className={`ListLinks ${className}`}>
        {links.map((link, id) => {
          const formattedLink = {
            ...link,
            className: `ListLinks-link inline-block relative ${
              link.href && (link.tagElt === 'a' || link.tagElt === 'Link')
                ? "before:absolute before:content-[''] before:bottom-none before:left-none before:h-4xs before:bg-[currentColor] before:pointer-events-none before:w-none before:transition-[width] before:duration-900 before:ease-[cubic-bezier(.135,.9,.15,1)] hover:before:w-full focus:outline-black-black focus:outline-2 focus:outline-offset-2"
                : ''
            }`,
          }
          return (
            <li key={id}>
              <LinkItem
                {...formattedLink}
                {...(onClose && { onClick: onClose })}
              />
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
