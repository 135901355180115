'use client'
import { useForm } from 'react-hook-form'
import { ISearchForm, ISearchFormData } from '~@Types/components/ISearchForm'
import ButtonPrimary from '~@Atoms/button-primary/button-primary'
import { Warning } from '@phosphor-icons/react'
import EditorialWysiwyg from '~@Organisms/editorial-wysiwyg/editorial-wysiwyg'
import { useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/navigation'
export default function App({
  wysiwyg,
  labels,
  messages,
  submitLabel,
  onClose,
}: ISearchForm) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISearchFormData>()
  const router = useRouter()
  const fields = useMemo(() => {
    return {
      searchField: {
        label: labels.message,
        type: 'input',
        required: messages.requiredField,
      },
    }
  }, [labels.message, messages.requiredField])

  const labelRefs = useRef<{ [key: string]: HTMLLabelElement | null }>({})

  useEffect(() => {
    Object.keys(fields).forEach((name) => {
      const label = labelRefs.current[name]
      const input = document.getElementById(name) as HTMLInputElement

      if (input && label) {
        const handleFocus = () => {
          label.classList.remove('top-1/2')
          label.classList.add('top-none')
        }

        const handleBlur = () => {
          if (input.value === '') {
            label.classList.add('top-1/2')
            label.classList.remove('top-none')
          }
        }

        input.addEventListener('focus', handleFocus)
        input.addEventListener('blur', handleBlur)

        return () => {
          input.removeEventListener('focus', handleFocus)
          input.removeEventListener('blur', handleBlur)
        }
      }
    })
  }, [fields])

  const onSubmit = async (data: ISearchFormData) => {
    if (onClose) onClose()
    await router.push(`/search/?search=${encodeURIComponent(data.searchField)}`)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="desktop:grid desktop:grid-cols-12 desktop:gap-3xl">
        {wysiwyg && (
          <EditorialWysiwyg
            className="desktop:col-start-1 desktop:col-end-6 mb-lg desktop:mb-none"
            wysiwyg={wysiwyg}
          />
        )}
        {Object.entries(fields).map(([name, options]) => {
          return (
            <div key={name} className="desktop:col-start-6 desktop:col-end-13">
              <div className="relative">
                <label
                  ref={(el) => {
                    labelRefs.current[name] = el
                  }}
                  className={`absolute transition-all duration-250 top-1/2 typeface-subtitle2-standard transform -translate-y-1/2 h-full w-full flex gap-2xs items-center pl-xs pr-md border-box ${errors[name] ? 'text-system-danger' : ''}`}
                  htmlFor={name}
                >
                  <span className="bg-white-white px-xs">{options.label}</span>
                </label>
                <input
                  id={name}
                  {...register(name as keyof ISearchFormData, {
                    required: options.required,
                  })}
                  className={`bg-white-white typeface-subtitle2-standard border-xs p-md rounded-xl focus:text-inherit focus:outline-black-black focus:outline-2 focus:outline-offset-2 w-full ${errors[name] ? `text-system-danger/50 border-system-danger` : `text-black-60 border-grey-semi`}`}
                  aria-required={
                    typeof options?.required !== 'undefined' ? 'true' : 'false'
                  }
                  type={options.type}
                />
              </div>

              {errors[name] && (
                <p
                  aria-describedby={name}
                  className="mt-md text-system-danger typeface-caption-special flex items-center"
                >
                  <Warning size={16} className="mr-xs" /> {errors[name].message}
                </p>
              )}
              <div className="mt-md">
                <ButtonPrimary
                  label={submitLabel}
                  link={{
                    tagElt: 'button',
                    type: 'submit',
                  }}
                />
              </div>
            </div>
          )
        })}
      </div>
    </form>
  )
}
