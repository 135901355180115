'use client'
import { useEffect } from 'react'

const CookieAxeptio = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.setAttribute('src', 'https://static.axept.io/sdk-slim.js')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('async', 'true')
    script.setAttribute('data-id', `${process.env.NEXT_PUBLIC_AXEPTIO_ID}`)
    script.setAttribute('data-cookies-version', 'ada web-base')

    if (document.body !== null) {
      document.body.appendChild(script)
    }
  }, [])

  return null
}

export default CookieAxeptio
