'use client'

import { useRef, useState } from 'react'

import LinkItem from '~@Components/atoms/link-item/link-item'
import { IBlockMenu } from '~@Types/components/IBlockMenu'
import { CaretUp, CaretDown } from '@phosphor-icons/react/dist/ssr'

export default function BlockMenuAlternate({
  links,
  ariaLabel,
  ariaHidden,
  onClose,
}: IBlockMenu) {
  const [expandedMenus, setExpandedMenus] = useState<boolean[]>([])
  const menuRefs = useRef<(HTMLUListElement | null)[]>([])

  const toggleMenu = (index: number) => {
    setExpandedMenus((prev) => {
      const updated = [...prev]
      updated[index] = !updated[index]
      return updated
    })
  }

  const computedLinks = (links: any[], isSubMenu: boolean) => {
    return links.map((link, index) => {
      const { subLinks, ...restLinkProps } = link

      return (
        <li key={index} className="mb-md last:mb-none" role="none">
          <LinkItem
            onClick={() => onClose?.()}
            role="menuitem"
            {...restLinkProps}
          />
        </li>
      )
    })
  }

  const computedList = () => {
    return links.map((link, index) => {
      const { subLinks, ...restLinkProps } = link

      const isExpanded = expandedMenus[index] || false

      if (subLinks && subLinks.length > 0) {
        return (
          <li className="mb-2xl" key={index}>
            <LinkItem
              className="flex items-center gap-xs typeface-heading-a4-standard"
              tagElt={'button'}
              aria-expanded={isExpanded}
              aria-controls={`subMenu-${index}`}
              onClick={() => toggleMenu(index)}
            >
              {link.children}
              {isExpanded ? (
                <CaretUp size={16} weight="bold" />
              ) : (
                <CaretDown size={16} weight="bold" />
              )}
            </LinkItem>
            <ul
              ref={(el) => {
                menuRefs.current[index] = el
              }}
              className={`overflow-hidden transition-all duration-500 ease-in-out ${isExpanded ? 'opacity-100 mt-2xl' : 'opacity-0'}`}
              id={`subMenu-${index}`}
              style={{
                maxHeight: isExpanded
                  ? `${menuRefs.current[index]?.scrollHeight}px`
                  : '0px',
              }}
            >
              {computedLinks(subLinks, true)}
            </ul>
          </li>
        )
      } else {
        return (
          <li
            className="mb-2xl typeface-heading-a4-standard text-white-white"
            key={index}
          >
            <LinkItem
              onClick={onClose && (() => onClose())}
              role="menuitem"
              {...restLinkProps}
            />
          </li>
        )
      }
    })
  }

  return (
    <nav
      role="navigation"
      {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
      {...(ariaHidden ? { 'aria-hidden': ariaHidden } : {})}
    >
      <ul>{computedList()}</ul>
    </nav>
  )
}
