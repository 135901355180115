import { twMerge } from 'tailwind-merge'
import { ArrowUpRight } from '@phosphor-icons/react/dist/ssr'

import LinkItem from '~@Atoms/link-item/link-item'
import { IButton } from '~@Types/components/IButton'

export default function ButtonOutline({
  className,
  link,
  label,
  isReversed,
}: IButton) {
  return (
    <LinkItem
      className={twMerge(
        'ButtonOutline group flex items-center typeface-button1-special',
        className
      )}
      {...link}
    >
      <span
        className={`ButtonOutline-label px-md py-sm rounded-full inline-block border-xs ${isReversed ? 'text-brand-primary group border-brand-primary' : 'text-white-white group border-white-white'}`}
      >
        {label}
      </span>
      <span
        aria-hidden="true"
        className={`ButtonOutline-icon rounded-full p-sm ease-in-out transition duration-300 border-xs group group-hover:translate-x-xs group-hover:rotate-45 ${isReversed ? ' border-brand-primary' : 'border-white-white'}`}
      >
        <ArrowUpRight
          className={isReversed ? 'text-brand-primary' : 'text-white-white'}
          size={16}
          weight="bold"
        />
      </span>
    </LinkItem>
  )
}
