'use client'

import { useState, useRef, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { CaretDown } from '@phosphor-icons/react'

import LinkItem from '~@Atoms/link-item/link-item'
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react'

import { ISwitcherLanguageProps } from '~@Types/components/ISwitcherLanguageProps'

const SwitcherLanguage = ({
  className,
  menu,
  triggerLabel,
}: ISwitcherLanguageProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      buttonRef.current?.focus()
    }
  }, [isOpen])

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false)
      buttonRef.current?.focus()
    }
  }

  return (
    <Popover className="SwitcherLanguage relative typeface-caption-standard text-inherit">
      <PopoverButton
        className={twMerge(
          className,
          "SwitcherLanguage-trigger ease-out focus:outline-black-black focus:outline-2 focus:outline-offset-2 duration-500 py-medium pl-medium typeface-caption-standard transition-colors flex items-center before:absolute before:content-[''] before:bottom-none before:left-none before:h-4xs before:bg-[currentColor] before:pointer-events-none before:w-none before:transition-[width] before:duration-900 before:ease-[cubic-bezier(.135,.9,.15,1)] hover:before:w-full focus:outline-none"
        )}
        ref={buttonRef}
        aria-expanded={isOpen}
        aria-controls="language-menu"
        tabIndex={0}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
      >
        <span className="lowercase first-letter:uppercase">{triggerLabel}</span>
        <CaretDown size={16} className="ml-2xs" />
      </PopoverButton>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel
          className="SwitcherLanguage-panel absolute w-full top-full left-1/2 -translate-x-1/2 bg-white"
          id="language-menu"
        >
          <ul className="SwitcherLanguage-list">
            {menu.map((item, key) => (
              <li key={key} className="SwitcherLanguage-item">
                {item.tagElt === 'button' ? (
                  <LinkItem
                    tagElt="button"
                    className="SwitcherLanguage-link block w-full text-left pt-xs lowercase first-letter:uppercase transition-colors text-inherit"
                    onClick={item.onClick}
                    type={item.type}
                  >
                    {item.children}
                  </LinkItem>
                ) : (
                  <LinkItem
                    className="SwitcherLanguage-link block w-full pt-xs lowercase first-letter:uppercase transition-colors text-inherit"
                    {...item}
                  />
                )}
              </li>
            ))}
          </ul>
        </PopoverPanel>
      </Transition>
    </Popover>
  )
}

export default SwitcherLanguage
