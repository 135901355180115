'use client'

import React from 'react'
import { useEffect, useState } from 'react'

import SiteLogo from '~@Atoms/site-logo/site-logo'
import ScrollTop from '~@Atoms/scroll-top/scroll-top'
import BlockAddress from '~@Molecules/block-address/block-address'
import BlockCorporate from '~@Molecules/block-corporate/block-corporate'
import NavSocial from '~@Molecules/nav-social/nav-social'
import ListLinks from '~@Molecules/list-links/list-links'

import { ISectionFooter } from '~@Types/components/ISectionFooter'

export default function SectionFooter({
  social,
  logo,
  address,
  navigation,
  corporate,
  copyright,
  legals,
  scrollTopLabel,
  navLabels,
}: ISectionFooter) {
  const formattedLegalsMenuWithCopyright = [
    {
      tagElt: 'span',
      children: copyright,
    },
    ...legals,
  ]

  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
    window.innerWidth < 1280 ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isMobile])

  return (
    <footer role="contentinfo" className="SectionFooter transform-gpu">
      <div className="SectionFooter-wrapper w-full relative bg-brand-primary rounded-b-2xl pt-8xl desktop-large:pt-3xl desktop-large:pb-xl desktop-large:grid desktop-large:grid-cols-12 desktop-large:gap-x-3xl overflow-hidden text-white-white">
        {social && (
          <>
            <div className="absolute z-10 top-none left-none bg-white-white pr-md pb-md rounded-br-2xl box-border antialiased">
              <div
                aria-hidden="true"
                className="absolute z-10 top-none right-none transform translate-x-full w-2xl h-2xl bg-white-white antialiased"
              >
                <div className="w-[41px] h-[41px] -mb-4xs -mr-4xs bg-brand-primary rounded-tl-2xl"></div>
              </div>
              <NavSocial {...social} />
              <div className="absolute z-10 bottom-none left-none transform translate-y-full w-2xl h-2xl bg-white-white antialiased">
                <div className="w-[41px] h-[41px] -mr-4xs -mb-4xs bg-brand-primary rounded-tl-2xl"></div>
              </div>
            </div>
          </>
        )}
        <div className="SectionFooter-wrapper desktop-large:grid desktop-large:col-start-2 desktop-large:col-end-13 desktop-large:grid-cols-subgrid antialiased">
          <SiteLogo
            className="absolute z-10 top-lg left-[50%] transform -translate-x-1/2 desktop-large:relative desktop-large:top-none desktop-large:left-none desktop-large:transform-none desktop-large:col-span-4 desktop-large:col-start-1 desktop-large:col-end-2"
            {...logo}
            isReversed={true}
            isReduced={isMobile}
          />
          <div className="relative z-10 flex flex-col items-start tablet:grid tablet:grid-cols-3 desktop-large:col-start-3 desktop-large:col-end-9 desktop-large:gap-4xl desktop-large:gap-y-md desktop-large:mt-xl">
            <ListLinks
              ariaLabel={navLabels.corpoNav}
              links={navigation}
              classNameNav="col-span-2"
              className="SectionFooter-navigation grid grid-cols-2 gap-3xl gap-y-md typeface-body2-standard p-md border-box desktop-large:p-none"
            />
            {address && (
              <BlockAddress
                className="SectionFooter-address p-md border-box col-span-1 desktop-large:p-none"
                {...address}
              />
            )}
          </div>
          {corporate && (
            <BlockCorporate
              className="SectionFooter-corporate flex flex-col items-center p-md border-box desktop-large:p-none desktop-large:items-start desktop-large:col-start-9 desktop-large:col-end-12"
              {...corporate}
            />
          )}
        </div>
        <div className="SectionFooter-footer relative typeface-caption-standard rounded-tl-2xl p-md ml-4xl bg-white-white text-brand-primary tablet:text-white-white tablet:py-md tablet:ml-none tablet:bg-brand-primary desktop-large:col-start-2 desktop-large:col-end-13 desktop-large:col-span-11 desktop-large:mt-6xl">
          <ListLinks
            ariaLabel={navLabels.legalNav}
            className="SectionFooter-legals flex flex-col gap-y-md tablet:flex-row tablet:justify-center tablet:align-middle tablet:gap-x-xl desktop-large:justify-start desktop-large:gap-x-3xl"
            links={formattedLegalsMenuWithCopyright}
          />
          <div
            aria-hidden="true"
            className="absolute z-10 top-4xs right-none transform -translate-y-full w-2xl h-2xl bg-white-white tablet:hidden antialiased box-content border-b-xs border-b-solid border-b-white-white"
          >
            <div className="w-[41px] h-[41px] -mt-4xs -ml-4xs bg-brand-primary rounded-br-2xl"></div>
          </div>
          <div
            aria-hidden="true"
            className="absolute z-10 bottom-none left-none transform -translate-x-full w-2xl h-2xl bg-white-white desktop:hidden antialiased"
          >
            <div className="w-[41px] h-[41px] -mt-4xs -ml-4xs bg-brand-primary rounded-br-2xl"></div>
          </div>
        </div>
        <div className="absolute top-none right-none bg-white-white pl-md pb-md rounded-bl-2xl box-border antialiased">
          <div
            aria-hidden="true"
            className="absolute z-10 top-none left-none transform -translate-x-full w-2xl h-2xl bg-white-white antialiased"
          >
            <div className="w-[41px] h-[41px] -mb-4xs -ml-4xs bg-brand-primary rounded-tr-2xl"></div>
          </div>
          <ScrollTop label={scrollTopLabel} ariaLabel={scrollTopLabel} />
          <div
            aria-hidden="true"
            className="absolute z-10 bottom-none right-none transform translate-y-full w-2xl h-2xl bg-white-white antialiased"
          >
            <div className="w-[41px] h-[41px] -ml-4xs -mb-4xs bg-brand-primary rounded-tr-2xl"></div>
          </div>
        </div>
      </div>
    </footer>
  )
}
