'use client'

import React from 'react'
import { useLocale } from 'next-intl'
import { usePathname, useRouter } from 'next/navigation'

import { useAppSelector } from '~@Store/hooks'
import { RootState } from '~@Store/store'

import SwitcherLanguage from '~@Molecules/switcher-language/switcher-language'

const SwitcherLanguageModule = ({ className }: { className?: string }) => {
  const locale = useLocale()
  const pathname = usePathname()
  const router = useRouter()
  const localesStore = useAppSelector((state: RootState) => state.localesStore)

  const handleLanguageChange = (newLocale: string, path: string) => {
    router.push(path)
    router.refresh()
  }

  const localesMenu =
    localesStore?.locales
      ?.filter((language) => language !== locale)
      .map((language) => {
        const segmentedPath = pathname.split('/').filter(Boolean)
        if (localesStore.locales.includes(segmentedPath[0])) {
          segmentedPath.shift()
        }
        segmentedPath.unshift(language)

        const newPath = `/${segmentedPath.join('/')}`

        return {
          tagElt: 'button',
          onClick: () => handleLanguageChange(language, newPath),
          children: language.toUpperCase(),
          type: 'button' as const,
        }
      }) || []

  const customMenu =
    localesStore?.customLocalLinks
      ?.filter(
        (language) =>
          language.locale !== locale && language.locale !== undefined
      )
      .map((language) => ({
        tagElt: 'button',
        onClick: () => handleLanguageChange(language.locale!, language.link),
        children: language?.locale?.toUpperCase(),
        type: 'button' as const,
      })) || []

  const menu = customMenu.length > 0 ? customMenu : localesMenu
  const triggerLabel = locale.toUpperCase()

  return (
    <SwitcherLanguage
      className={className}
      menu={menu.map((item) => ({
        ...item,
        type: 'button' as const,
      }))}
      triggerLabel={triggerLabel}
    />
  )
}

export default SwitcherLanguageModule
