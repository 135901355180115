'use client'

import BlockPicture from '~@Atoms/block-picture/block-picture'
import LinkItem from '~@Components/atoms/link-item/link-item'
import { twMerge } from 'tailwind-merge'

import { ArrowUpRight } from '@phosphor-icons/react/dist/ssr'
import { ITeaserDescription } from '~@Types/components/ITeaserDescription'

const TeaserDescription = ({
  title,
  description,
  link,
  picture,
  className,
}: ITeaserDescription) => {
  return (
    <LinkItem
      {...link}
      className={twMerge(
        'TeaserDescription p-lg rounded-lg bg-grey-x-light flex flex-col group relative',
        className
      )}
    >
      <div className="flex-1">
        <h3 className="TeaserDescription-title typeface-button1-standard mb-xs">
          {title}
        </h3>
        {description && (
          <p className="TeaserDescription-desc text-grey-dark typeface-subtitle2-standard">
            {description}
          </p>
        )}
      </div>
      {picture && (
        <div className="mt-lg rounded-xl overflow-hidden">
          <BlockPicture
            className="cover w-full group-hover:scale-105 transition-transform duration-500"
            {...picture}
          />
          <span
            aria-hidden="true"
            className="absolute bottom-lg right-lg bg-grey-x-light pt-xs pl-xs rounded-tl-lg block"
          >
            <span
              aria-hidden="true"
              className="h-[32px] w-[32px] top-none right-none -translate-y-[100%] absolute block overflow-hidden before:block before:content[''] before:h-[32px] before:w-[32px] before:rounded-br-[60%] before:shadow-[0_0_0_32px_#f5F5f5]"
            />
            <span
              aria-hidden="true"
              className="rounded-full bg-brand-secondary p-sm block"
            >
              <ArrowUpRight
                size={16}
                color="white"
                weight="bold"
                className="transition-transform"
              />
            </span>
            <span
              aria-hidden="true"
              className="h-[32px] w-[32px] bottom-none left-none -translate-x-[100%] absolute block overflow-hidden before:block before:content[''] before:h-[32px] before:w-[32px] before:rounded-br-[60%] before:shadow-[0_0_0_32px_#f5F5f5]"
            />
          </span>
        </div>
      )}
    </LinkItem>
  )
}

export default TeaserDescription
