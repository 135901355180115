'use client'

import React from 'react'

import SectionHeader from '~@Organisms/section-header/section-header'

import { useGTM } from '~@Hooks/useGTM'

const LayerHeaderClient = ({
  logoCfg,
  mainMenuDesktop,
  mainMenuMobile,
  secondaryMenu,
  headerCTA,
  headerLabels,
  searchFormCfg,
}) => {
  const { trackContactCTA } = useGTM()

  const headerCTAWithTracking =
    headerCTA && headerCTA.link
      ? {
          ...headerCTA,
          onClick: () => {
            const path =
              typeof window !== 'undefined' ? window.location.pathname : '/'
            trackContactCTA(path)
          },
        }
      : null

  return (
    <SectionHeader
      logo={logoCfg}
      links={mainMenuDesktop}
      linksMobile={mainMenuMobile}
      secondaryMenu={secondaryMenu}
      button={headerCTAWithTracking}
      labels={headerLabels}
      searchForm={searchFormCfg}
    />
  )
}

export default LayerHeaderClient
