import LinkItem from '~@Atoms/link-item/link-item'
import { ArrowUpRight } from '@phosphor-icons/react/dist/ssr'
import { IExtraLink } from '~@Types/components/IExtraLink'

export default function ExtraLink({ link, label, description }: IExtraLink) {
  return (
    <LinkItem
      className="ExtraLink group p-md rounded-lg hover:bg-grey-x-light block relative"
      {...link}
    >
      <span
        aria-hidden="true"
        className="absolute top-xl right-xl text-grey-dark opacity-0 transition-all group-hover:opacity-100 duration-500 group-hover:translate-x-sm group-hover:-translate-y-sm"
      >
        <ArrowUpRight size={16} weight="bold" />
      </span>
      <p className="ExtraLink-title typeface-button1-standard mb-2xs">
        {label}
      </p>
      {description && (
        <p className="ExtraLink-desc text-grey-dark typeface-subtitle2-standard">
          {description}
        </p>
      )}
    </LinkItem>
  )
}
