'use client'

import React from 'react'

import SectionFooter from '~@Organisms/section-footer/section-footer'

import { useGTM } from '~@Hooks/useGTM'
import { ISectionFooter } from '@/types/components/ISectionFooter'
import { IBlockCorporate } from '@/types/components/IBlockCorporate'

const LayerFooterClient = ({
  social,
  logo,
  navigation,
  address,
  corporate,
  copyright,
  legals,
  scrollTopLabel,
  navLabels,
}: ISectionFooter) => {
  const { trackContactCTA, trackJoinUsCTA } = useGTM()

  const corporateWithTracking: IBlockCorporate | null | undefined =
    (corporate && corporate.primaryLink) ||
    (corporate && corporate.secondaryLink)
      ? {
          ...corporate,
          primaryLink: {
            ...corporate.primaryLink,
            link: {
              ...corporate.primaryLink?.link,
              onClick: () => {
                const path =
                  typeof window !== 'undefined' ? window.location.pathname : '/'
                trackContactCTA(path)
              },
            },
          },
          secondaryLink: corporate.secondaryLink
            ? {
                ...corporate.secondaryLink,
                link: {
                  ...corporate.secondaryLink.link,
                  onClick: () => {
                    const path =
                      typeof window !== 'undefined'
                        ? window.location.pathname
                        : '/'
                    trackJoinUsCTA(path)
                  },
                },
              }
            : undefined,
        }
      : corporate

  return (
    <div className="container px-lg tablet:px-3xl mt-5xl desktop-large:mt-9xl">
      <SectionFooter
        social={social}
        logo={logo}
        navigation={navigation}
        address={address}
        corporate={corporateWithTracking}
        copyright={copyright}
        legals={legals}
        scrollTopLabel={scrollTopLabel}
        navLabels={navLabels}
      />
    </div>
  )
}

export default LayerFooterClient
