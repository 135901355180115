import React from 'react'
import { twMerge } from 'tailwind-merge'

import ButtonSecondary from '~@Components/atoms/button-secondary/button-secondary'
import ButtonOutline from '~@Atoms/button-outline/button-outline'

import { IBlockCorporate } from '~@Types/components/IBlockCorporate'

export default function BlockCorporate({
  className,
  title,
  primaryLink,
  secondaryLink,
}: IBlockCorporate) {
  return (
    <section
      className={twMerge('BlockCorporate', className)}
      aria-labelledby="block-corporate-title"
    >
      <p
        id="block-corporate-title"
        className="BlockCorporate-title typeface-heading-a4-standard"
      >
        {title}
      </p>
      <div
        className="BlockCorporate-actions flex gap-x-sm mt-md mb-2xl desktop-large:mt-xl desktop-large:mb-none desktop-large:flex-col desktop-large:gap-y-md"
        role="group"
      >
        {primaryLink && <ButtonSecondary {...primaryLink} />}
        {secondaryLink && <ButtonOutline {...secondaryLink} />}
      </div>
    </section>
  )
}
