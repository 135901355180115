'use client'

import { useCallback } from 'react'
import { twMerge } from 'tailwind-merge'
import { ArrowUp } from '@phosphor-icons/react/dist/ssr'
import { IScrollTop } from '~@Types/components/IScrollTop'

export default function ScrollTop({ className, label, ariaLabel }: IScrollTop) {
  const scrollToTop = useCallback(() => {
    if (typeof window !== undefined) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [])

  return (
    <button
      className={twMerge(
        'ScrollTop group flex items-center rounded-full text-brand-primary bg-grey-semi focus:outline-black-black focus:outline-2 focus:outline-offset-2',
        className
      )}
      onClick={scrollToTop}
      aria-label={ariaLabel}
    >
      <span className="ScrollTop-label sr-only">{label}</span>
      <span
        aria-hidden="true"
        className="ScrollTop-icon p-md transition-transform group-hover:-translate-y-xs group-focus:-translate-y-xs"
      >
        <ArrowUp size={23} weight="bold" />
      </span>
    </button>
  )
}
